import { Button, Grid, Icon } from '@mui/material';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useNavigate } from 'react-router-dom';
import { useProfile } from '../../../context/ProfileContext';

export const ListagemRelatoriosPresentation = () => {
  const navigate = useNavigate();
  const { isSuperAdmin } = useProfile();

  return (
    <LayoutBaseDePagina titulo="Relatorios" subtitulo="Relatorios">
      {isSuperAdmin && (
        <>
          <Grid
            item
            display="flex"
            bgcolor={'white'}
            padding={1}
            paddingLeft={'14px'}
            marginTop={'15px'}
            marginRight={'8px'}
            marginBottom={0}
            marginLeft={'18px'}
            borderRadius={'5px'}
          >
            <Button
              color="primary"
              disableElevation
              variant={'contained'}
              startIcon={<Icon>input</Icon>}
              onClick={() => navigate('/summary/financial')}
            >
              {'Resumo financeiro'}
            </Button>
          </Grid>
          <Grid
            item
            display="flex"
            bgcolor={'white'}
            padding={1}
            paddingLeft={'14px'}
            marginTop={'15px'}
            marginRight={'8px'}
            marginBottom={0}
            marginLeft={'18px'}
            borderRadius={'5px'}
          >
            <Button
              color="primary"
              disableElevation
              variant={'contained'}
              startIcon={<Icon>input</Icon>}
              onClick={() => navigate('/report/update-proposal')}
            >
              {'Alterações de garantia'}
            </Button>
          </Grid>
          <Grid
            item
            display="flex"
            bgcolor={'white'}
            padding={1}
            paddingLeft={'14px'}
            marginTop={'15px'}
            marginRight={'8px'}
            marginBottom={0}
            marginLeft={'18px'}
            borderRadius={'5px'}
          >
            <Button
              color="primary"
              disableElevation
              variant={'contained'}
              startIcon={<Icon>input</Icon>}
              onClick={() => navigate('/report/proposal')}
            >
              {'Relatório de  garantia'}
            </Button>
          </Grid>

          <Grid
            item
            display="flex"
            bgcolor={'white'}
            padding={1}
            paddingLeft={'14px'}
            marginTop={'15px'}
            marginRight={'8px'}
            marginBottom={0}
            marginLeft={'18px'}
            borderRadius={'5px'}
          >
            <Button
              disableElevation
              variant={'contained'}
              startIcon={<Icon>input</Icon>}
              onClick={() => navigate('/report/analysis')}
            >
              Relatório de Análises
            </Button>
          </Grid>
          <Grid
            item
            display="flex"
            bgcolor={'white'}
            padding={1}
            paddingLeft={'14px'}
            marginTop={'15px'}
            marginRight={'8px'}
            marginBottom={0}
            marginLeft={'18px'}
            borderRadius={'5px'}
          >
            <Button
              disableElevation
              variant={'contained'}
              startIcon={<Icon>input</Icon>}
              onClick={() => navigate('/report/proposal/analytics')}
            >
              Histórico de Garantias
            </Button>
          </Grid>
          <Grid
            item
            display="flex"
            bgcolor={'white'}
            padding={1}
            paddingLeft={'14px'}
            marginTop={'15px'}
            marginRight={'8px'}
            marginBottom={0}
            marginLeft={'18px'}
            borderRadius={'5px'}
          >
            <Button
              disableElevation
              variant={'contained'}
              startIcon={<Icon>input</Icon>}
              onClick={() => navigate('/report/analysis/analytics')}
            >
              Hitórico de Análises
            </Button>
          </Grid>
          <Grid
            item
            display="flex"
            bgcolor={'white'}
            padding={1}
            paddingLeft={'14px'}
            marginTop={'15px'}
            marginRight={'8px'}
            marginBottom={0}
            marginLeft={'18px'}
            borderRadius={'5px'}
          >
            <Button
              disableElevation
              variant={'contained'}
              startIcon={<Icon>input</Icon>}
              onClick={() => navigate('/client-contract/analytics')}
            >
              Contratos Imobiliárias
            </Button>
          </Grid>
        </>
      )}
    </LayoutBaseDePagina>
  );
};
