import { Box, Button, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { CardDocument } from "./CardDocument";
import { useState } from "react";
import { ModalContext } from "../../../shared/components";
import { CenterFocusStrongOutlined, Download, FullscreenRounded, PageviewOutlined, Preview } from "@mui/icons-material";

const estateTerms = require("../../../assets/files/estate_terms.pdf");
const tenantClause = require("../../../assets/files/tenant_clause.pdf");
const tenantTerms = require("../../../assets/files/tenant_terms.pdf");
const declaracaoDebito = require("../../../assets/files/declaracao_debito.docx");
const inexistenciaDebito = require("../../../assets/files/inexistencia_debitos.docx");
const termoEntregaChaves = require("../../../assets/files/termo_entrega_chaves.docx");
const procuracaoBCM = require("../../../assets/files/procuracao_BCM_2023.docx");
const procuracaoALTAC = require("../../../assets/files/procuracao_ALTAC.docx");
const procuracaoCostodio = require("../../../assets/files/procuracao_costodio.docx");

export const ListagemGeraisPresentation = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [openModalTermination, setOpenModalTermination] = useState(false);
  const [openModalCancel, setOpenModalCancel] = useState(false);
  const [openModalView, setOpenModalView] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [contents, setContents] = useState<string[]>([]);
  const URL = "https://s3.amazonaws.com/cdn.pub.staging/";
  const [URLView, setURLView] = useState<string>("");
  const [nameDocument, setNameDocument] = useState<string>("" as string);

  const fetchData3 = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await fetch(URL);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const xmlText = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlText, "application/xml");
      const contentElements = xmlDoc.getElementsByTagName("Contents");
      const contentValues: string[] = Array.from(contentElements).map(
        (element) => element.textContent || ""
      );
      setContents(contentValues);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleModalCancel = () => {
    fetchData3();
    setOpenModalCancel(true);
  };

  const handleModalTermination = () => {
    fetchData3();
    setOpenModalTermination(true);
    console.log("handleModalTermination")
  };

  return (
    <LayoutBaseDePagina
      titulo="Gerais"
      subtitulo="Gerais - Documentos auxiliares"
    >
      <Box
        display={"grid"}
        gridTemplateColumns={smDown ? "1fr" : "1fr 1fr 1fr 1fr 1fr "}
        flexDirection={smDown ? "column" : "row"}
        padding={1}
        paddingY={2}
        alignItems={"flex-start"}
        gap={2}
      >
        <CardDocument
          height={smDown ? "300px" : "200px"}
          title="Contrato Imobiliaria"
          logo="ContractHome.svg"
          onclickButton={() => window.open(estateTerms)}
        />

        <CardDocument
          height={smDown ? "300px" : "200px"}
          title="Contrato Inquilino"
          logo="ContractTenant.svg"
          onclickButton={() => window.open(tenantTerms)}
        />

        <CardDocument
          height={smDown ? "300px" : "200px"}
          title="Cláusula Garantti"
          logo="ContractGarantti.svg"
          onclickButton={() => window.open(tenantClause)}
        />

        <CardDocument
          height={smDown ? "300px" : "200px"}
          title="Declaração de Débitos"
          logo="RelationDebit.svg"
          onclickButton={() => window.open(declaracaoDebito)}
        />

        <CardDocument
          height={smDown ? "300px" : "200px"}
          title="Termo de inexistência de Débitos"
          logo="ContractNotDebit.png"
          onclickButton={() => window.open(inexistenciaDebito)}
        />

        <CardDocument
          height={smDown ? "300px" : "200px"}
          title="Termo Entrega Chaves"
          logo="ContractKey.png"
          onclickButton={() => window.open(termoEntregaChaves)}
        />

        <CardDocument
          height={smDown ? "300px" : "200px"}
          title="Procuração BCM"
          logo="bcm.svg"
          onclickButton={() => window.open(procuracaoBCM)}
        />

        <CardDocument
          height={smDown ? "300px" : "200px"}
          title="Procuração ALTAC"
          logo="altac.svg"
          onclickButton={() => window.open(procuracaoALTAC)}
        />

        <CardDocument
          height={smDown ? "300px" : "200px"}
          title="Procuração Costódio"
          logo="ContractHome.svg"
          onclickButton={() => window.open(procuracaoCostodio)}
        />

        <CardDocument
          height={smDown ? "300px" : "200px"}
          title="Documentos para cancelamento"
          logo="ContractHome.svg"
          onclickButton={() => handleModalCancel()}
        />
        <CardDocument
          height={smDown ? "300px" : "200px"}
          title="Termos de rescisões"
          logo="ContractHome.svg"
          onclickButton={() => handleModalTermination()}
        />
      </Box>

      {openModalView && !loading && (
        <ModalContext
          // ITitle={`${nameDocument}`}
          ISubTitle={`${nameDocument}`}
          IMessage={[
            <iframe
              src={URLView}
              width="750px"
              height="350px"
              style={{ border: "none" }}
            />,
          ]}
          isOpenModal={openModalView}
          messageType="info"
          onclickConfirm={() => setOpenModalView(false)}
          showButtonConfirm={true}
          textButtonConfirm="Fechar"
          showTitleIcon={false}
          width="800px"
  
        />
      )}

      {openModalTermination && !loading && (
        <ModalContext
          ITitle="Termos de rescisões"
          IMessage={[
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {contents.map(
                (item: any, index: number) =>
                  item.startsWith("recisoes/") &&
                  item.includes(".docx") && (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Tooltip title="Clique para Baixar">
                        <Button
                          startIcon={<Download />}
                          key={index}
                          sx={{
                            textAlign: "left",
                            display: "flex",
                            justifyContent: "start",
                            color: "#202020",
                          }}
                          href={`${URL}recisoes/${
                            item.split(".docx")[0].split("recisoes/")[1]
                          }.docx`}
                          target="_blank"
                        >
                          {item.split(".docx")[0].split("recisoes/")[1]}
                        </Button>
                      </Tooltip>
                      <Tooltip title="Clique para Visualizar">
                        <Button
                          startIcon={<CenterFocusStrongOutlined />}
                          onClick={() => {
                            setURLView(
                              `https://view.officeapps.live.com/op/embed.aspx?src=${URL}recisoes/${
                                item.split(".docx")[0].split("recisoes/")[1]
                              }.docx`
                            );
                            setNameDocument(
                              item.split(".docx")[0].split("recisoes/")[1]
                            );
                            setOpenModalView(true);
                          }}
                        ></Button>
                      </Tooltip>
                    </Box>
                  )
              )}
            </Box>,
          ]}
          isOpenModal={openModalTermination}
          messageType="info"
          onclickConfirm={() => setOpenModalTermination(false)}
          showButtonConfirm={true}
          textButtonConfirm="Fechar"
        />
      )}
      
      {openModalCancel && !loading && (
        <ModalContext
          ITitle="Documentos para cancelamento"
          IMessage={[
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {contents.map(
                (item: any, index: number) =>
                  item.startsWith("cancelamento/") &&
                  item.includes(".docx") && (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Tooltip title="Clique para Baixar">
                        <Button
                          startIcon={<Download />}
                          key={index}
                          sx={{
                            textAlign: "left",
                            display: "flex",
                            justifyContent: "start",
                            color: "#202020",
                          }}
                          href={`${URL}cancelamento/${
                            item.split(".docx")[0].split("cancelamento/")[1]
                          }.docx`}
                          target="_blank"
                        >
                          {item.split(".docx")[0].split("cancelamento/")[1]}
                        </Button>
                      </Tooltip>
                      <Tooltip title="Clique para Visualizar">
                        <Button
                          startIcon={<CenterFocusStrongOutlined />}
                          onClick={() => {
                            setURLView(
                              `https://view.officeapps.live.com/op/embed.aspx?src=${URL}cancelamento/${
                                item.split(".docx")[0].split("cancelamento/")[1]
                              }.docx`
                            );
                            setNameDocument(
                              item.split(".docx")[0].split("cancelamento/")[1]
                            );
                            setOpenModalView(true);
                          }}
                        ></Button>
                      </Tooltip>
                    </Box>
                  )
              )}
            </Box>,
          ]}
          isOpenModal={openModalCancel}
          messageType="info"
          onclickConfirm={() => setOpenModalCancel(false)}
          showButtonConfirm={true}
          textButtonConfirm="Fechar"
        />
      )}
    </LayoutBaseDePagina>
  );
};
