import { Environment } from '../../../environment';
import { Api } from "../axios-config";

export interface IListagemClientType {
    id: string,
    description: string,
    status: string,
    clientTypeId: string,
    level:number
}

export type TClientTypeTotalCount = {
    data: IListagemClientType[];
    totalCount: number;
}


const getAll = async (page = 1, filter = ''): Promise<TClientTypeTotalCount> => {
    try {
        const urlRelativa = `/client-type?_page=${page}&_limit=${Environment.LIMITE_LINHAS}&description_like=${filter}`;
        const { data, headers } = await Api().get(urlRelativa);

        return {
          data: data.results,
          totalCount: data.total,
        };
    } catch (error) {
        console.error(error);
        throw new Error((error as { message: string }).message || 'Erro ao listar registros.');
    }
};

const getById = async (id: number): Promise<IListagemClientType | Error> => {
    try {
        const { data } = await Api().get(`/client-type/${id}`);

        if (data) {
            return data;
        }
        return new Error('Erro ao consultar o registro.');

    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
    }
};

const create = async (dados: Omit<IListagemClientType, 'id'>): Promise<string | Error> => {
    try {
        const { data } = await Api().post<IListagemClientType>('/ClientType', dados);

        if (data) {
            return data.id;
        }
        return new Error('Erro ao criar o registro.');
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
    }
};

const updateById = async (id: string, dados: IListagemClientType): Promise<void | Error> => {
    try {
        await Api().put(`/client-type/${id}`, dados);
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
    }
};

const deleteById = async (id: string): Promise<void | Error> => {
    try {
        await Api().delete(`/client-type/${id}`);
    } catch (error) {
        console.error(error);
        return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
    }
};


export const clientTypeService = {
    getAll,
    getById,
    create,
    updateById,
    deleteById
};